import { Controller } from '@hotwired/stimulus'

class SwitcherController extends Controller {
  static targets = ['switchLeft', 'switchRight']

  connect() {
    this.switchLeftTarget.classList.toggle('switcher__switch--active')

    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)
      if (searchParams.get('billable_mode') === 'true') {
        this.switchRightTarget.classList.add('switcher__switch--active')
        this.switchLeftTarget.classList.remove('switcher__switch--active')
      }
    }
  }

  toggle() {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)
      const currentValue = searchParams.get('billable_mode')
      const newValue = currentValue === 'true' ? 'false' : 'true'
      searchParams.set('billable_mode', newValue)
      window.location.search = searchParams.toString()
    }
  }
}

export default SwitcherController
