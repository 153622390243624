import moment from 'moment'

// courtesy of chatgpt...
export default function doRangesOverlap(range1Start, range1End, range2Start, range2End) {
  const momentRange1Start = moment(range1Start);
  const momentRange1End = moment(range1End);
  const momentRange2Start = moment(range2Start);
  const momentRange2End = moment(range2End);

  // Check if either range1Start or range1End is within the range of range2
  const isRange1InOverlap = momentRange1Start.isBetween(momentRange2Start, momentRange2End, null, '[]') ||
    momentRange1End.isBetween(momentRange2Start, momentRange2End, null, '[]');

  // Check if either range2Start or range2End is within the range of range1
  const isRange2InOverlap = momentRange2Start.isBetween(momentRange1Start, momentRange1End, null, '[]') ||
    momentRange2End.isBetween(momentRange1Start, momentRange1End, null, '[]');

  // Check if either range completely encompasses the other
  const isRange1Enclosing = momentRange1Start.isSameOrBefore(momentRange2Start) && momentRange1End.isSameOrAfter(momentRange2End);
  const isRange2Enclosing = momentRange2Start.isSameOrBefore(momentRange1Start) && momentRange2End.isSameOrAfter(momentRange1End);

  // Check for overlap
  return isRange1InOverlap || isRange2InOverlap || isRange1Enclosing || isRange2Enclosing;
}
