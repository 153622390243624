import { Controller } from '@hotwired/stimulus'
import moment from 'moment';

export default class DateInputController extends Controller {
  connect() {
    const url = new URL(window.location)
    this.element.value = url.searchParams.get('as_of')
  }

  search(event) {
    const date = new moment(event.target.value);
    window.location.search = `?as_of=${date.format('YYYY-MM-DD')}`;
  }
}
