import DateChartController from './date_chart_controller.js'

class CheckinChartController extends DateChartController {
  static values = {
    ...super.values,
    submissions: Array
  }

  _scales() {
    return {
      ...super._scales(),
      y1: {
        type: 'linear',
        display: true,
        position: 'right',
        min: 0,
        max: 100,
        ticks: {
          maxTicksLimit: 6,
          color: this._labelColor()
        },
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        }
      }
    }
  }

  _tooltipLabel(tooltipItem) {
    const defaultLabel = super._tooltipLabel(tooltipItem)
    if (tooltipItem.dataset.label.toLowerCase().includes('percent')) return defaultLabel + '%'

    return defaultLabel
  }

  _tooltipAfterBody(tooltipItem) {
    const submissionCount = this.submissionsValue[tooltipItem[0].dataIndex]
    if (!submissionCount) return

    return `Submissions: ${submissionCount}`
  }
}

export default CheckinChartController
