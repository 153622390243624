export default class RoleCallGroupTemplate {
  /**
   * This controls the rendering a vis.js group in the role call tool.j
   * @param group This is data from the vis.js dataset that represents a project or person group
   */
  constructor(group) {
    this._group = group
  }

  get group() { return this._group }

  render() {
    if (this._skipCustomRender()) {
      return this.group.content
    }

    const container = this._createContainer()
    const buttonContainer = this._createButtonContainer()

    if (this.group.placeholderProject) {
      buttonContainer.appendChild(this._createDeleteButton())
      buttonContainer.appendChild(this._createMergeButton())
    }

    buttonContainer.appendChild(this._createAddBookedTimeLink())
    container.append(buttonContainer)
    container.append(this.group.content)
    return container
  }

  _skipCustomRender() {
    return this.group.nestedInGroup
  }

  _createContainer() {
    const container = document.createElement('div')
    container.classList.add('flex', 'gap-xs', 'items-center')
    container.setAttribute('data-testid', 'project-group')
    return container
  }

  _createButtonContainer() {
    const buttonContainer = document.createElement('div')
    buttonContainer.classList.add('flex')
    return buttonContainer
  }

  _createDeleteButton() {
    const form = document.createElement("form")
    form.method = 'post'
    form.action = `/placeholder_projects/${this.group.id}`

    const hiddenInput = document.createElement("input")
    hiddenInput.type = 'hidden'
    hiddenInput.name = '_method'
    hiddenInput.value = 'delete'
    hiddenInput.autocomplete = 'off'
    form.appendChild(hiddenInput)

    const button = document.createElement('button')
    button.setAttribute('data-turbo-confirm', 'Are you sure?')
    button.setAttribute('data-turbo-frame', '_top')
    button.type = 'submit'
    button.classList.add('btn', 'btn--no-border', 'btn--small', 'role-call__group-action-button', 'role-call__delete-project-button')
    button.setAttribute('data-testid', 'delete-placeholder-project')

    const icon = document.createElement('span')
    icon.classList.add('material-symbols-outlined')
    icon.innerText = 'delete'
    button.appendChild(icon)
    form.appendChild(button)

    const authenticationHiddenInput = document.createElement('input')
    authenticationHiddenInput.type = 'hidden'
    authenticationHiddenInput.name = 'authenticity_token'
    authenticationHiddenInput.autocomplete = 'off'
    authenticationHiddenInput.value = this._csrfToken()
    form.appendChild(authenticationHiddenInput)
    return form
  }

  _createMergeButton() {
    const mergeButton = document.createElement('a')
    const icon = document.createElement('span')
    mergeButton.setAttribute('href', `/placeholder_projects/${this.group.id}/merges/new`)
    mergeButton.setAttribute('data-turbo-frame', 'modal')
    mergeButton.classList.add('btn', 'btn--no-border', 'btn--small', 'role-call__group-action-button')
    mergeButton.setAttribute('data-testid', 'merge-placeholder-project')
    icon.classList.add('material-symbols-outlined')
    icon.innerText = 'merge'
    mergeButton.appendChild(icon)
    return mergeButton
  }

  _createAddBookedTimeLink() {
    const icon = document.createElement('span')
    const link = document.createElement('a')
    link.setAttribute('href', this._url())
    link.setAttribute('data-turbo-frame', 'modal')
    link.setAttribute('data-testid', 'add-booked-time')
    link.setAttribute('id', `add-booked-time-link-${this.group.id}`)

    icon.classList.add('material-symbols-outlined')
    icon.innerText = 'person_add'
    link.appendChild(icon)
    link.classList.add('btn', 'btn--no-border', 'btn--small', 'role-call__group-action-button', 'role-call__add-booked-time-button')
    return link
  }

  _url() {
    const category = this.group.isProject ? 'projects' : 'people'

    return `/role_call/${category}/${this.group.id}/booked_times/new?layout=modal`
  }

  _csrfToken() {
    return this._csrfToken ??= document.querySelector(`meta[name='csrf-token']`).content
  }
}
