import { Timeline } from "vis-timeline/peer"
import { DataSet } from "vis-data/peer"
import moment from 'moment'
import RoleCallGroupTemplate from './RoleCallGroupTemplate'
import doRangesOverlap from '../../helpers/doRangesOverlap'
import ApiClient from './ApiClient.js'

export default class RoleCallTimeline {
  /**
   * This class wraps a vis.js timeline and handles the events and render methods needed to tie it to our app.
   */
  constructor(root, isEditable, bookedTimesController, groups, items) {
    this._timeline = new Timeline(root)
    this._isEditable = isEditable
    this._bookedTimesController = bookedTimesController
    this._groups = new DataSet(groups)
    this._items = new DataSet(items)
    this.timeline.setOptions(this._options())
    this.timeline.setGroups(this.groups)
    this.timeline.setItems(this.items)
    this._handleGroupVisibility()
    this.timeline.on('click', this._onClickTimeline.bind(this))
    this.timeline.on('select', this._onClickTimelineItem.bind(this))
    this.timeline.on('rangechanged', this._onRangeChanged.bind(this))
    const currentDateIndicator = root.querySelector('.vis-current-time')
    currentDateIndicator.setAttribute('data-current-date', new Date().getDate().toString())
    this.timeline
  }

  disconnect() {
    this.timeline.off('click', this._onClickTimeline.bind(this))
    this.timeline.off('select', this._onClickTimelineItem.bind(this))
  }

  apiClient() {
    return this._apiClient ??= new ApiClient()
  }

  _onClickTimeline(visEvent) {
    window.dispatchEvent(new CustomEvent('timeline-clicked', { detail: this.timeline.getEventProperties(visEvent) }))
  }

  _onClickTimelineItem(visEvent) {
    window.dispatchEvent(new CustomEvent('timeline-item-selected', { detail: this.timeline.getEventProperties(visEvent) }))
  }

  async _onRangeChanged({ start, end, _byUser, _event }) {
    if (start >= this.earliestDate) return

    const endDate = end < this.earliestDate ? end : this.earliestDate
    this._earliestDate = start
    const { groups, items } = await this.bookedTimesController.index(start, endDate)
    groups.forEach(group => this.groups.update(group))
    items.forEach(item => this.items.update(item))
  }

  get timeline() { return this._timeline }
  get isEditable() { return this._isEditable }
  get groups() { return this._groups }
  get items() { return this._items }
  get bookedTimesController() { return this._bookedTimesController }
  get earliestDate() { return this._earliestDate ??= moment().subtract(2, 'weeks').toDate() }
  /**
   * This differs from the add function below. In this case the data was saved to the server via
   * form and the front end just needs to be updated.
   */
  async addSavedBookedTime(data) {
    const projectView = !this._personView()

    const parentId = projectView ? data.projectId : data.personId
    const groupId = `${data.personId}-${data.projectId}`
    const newItem = {
      start: data.start,
      end: data.end,
      id: data.id,
      content: data.content,
      group: groupId,
      className: 'booked-time',
    }

    const group = {
      content: projectView ? data.personName : data.projectName,
      id: groupId,
      nestedInGroup: parentId,
      isProject: !projectView,
      className: this._groupClassName(data, projectView)
    }

    const parentGroup = this.groups.get().find(g => g.id === parentId)
    parentGroup.nestedGroups ? parentGroup.nestedGroups.push(groupId) : parentGroup.nestedGroups = [groupId]
    this.groups.update(group)
    this.items.update(newItem)
    return this._updateSummaryItems(groupId)
  }

  updateAll(items, groups) {
    this.items.forEach(i => this.items.remove(i))
    this.groups.forEach(g => this.groups.remove(g))

    groups.forEach(group => { this.groups.add(group) })
    items.forEach(item => { this.items.add(item) })
  }

  _groupClassName(data, projectView) {
    if (!projectView) {
      if (data.projectSupported) return 'vis-js__person-group-support'
      if (data.projectActive) return 'vis-js__person-group-active'
    }
  }

  async updateSavedBookedTime(data) {
    const groupId = `${data.personId}-${data.projectId}`
    const newItem = {
      start: data.start,
      end: data.end,
      id: data.id,
      content: data.content,
      group: groupId,
      className: 'booked-time',
    }

    this.items.updateOnly(newItem)
    return this._updateSummaryItems(groupId)
  }

  async _onAddBookedTime(item, _callback) {
    if (this._invalidBookedTimeSpot(item)) return

    const personId = item.group.split('-')[0]
    const projectId = item.group.split('-')[1]
    const link = document.getElementById(`add-booked-time-link-${projectId}`) || document.getElementById(`add-booked-time-link-${personId}`)
    const newUrl = link.href + `&person_id=${personId}&project_id=${projectId}&start_date=${item.start}&end_date=${item.end}`
    link.setAttribute('href', newUrl)
    link.click()
  }

  async _updateBookedTime(item, _callback) {
    const rootDiv = document.getElementById('timeline')
    const formTurboFrameTag = document.createElement('turbo-frame')
    formTurboFrameTag.setAttribute('data-controller', 'booked-time-popup')
    formTurboFrameTag.id = 'booked-time-popup-form'
    formTurboFrameTag.src = `role_call/booked_times/${item.id}/popup_form`
    formTurboFrameTag.classList.add('visibility-hidden')
    rootDiv.appendChild(formTurboFrameTag)
  }

  async _moveBookedTime(item, callback) {
    await this.bookedTimesController.update(item)
    await callback(item)
    await this._updateSummaryItems(item.group)
  }

  async _deleteBookedTime(item, callback) {
    await this.bookedTimesController.delete(item)
    await this._updateSummaryItems(item.group)
    await callback(item)

    const otherItemsInGroup = this.items.get().filter(i => {
      return i.group === item.group && item !== i && !i.id?.includes?.('constraint')
    })

    if (otherItemsInGroup.length === 0) {
      this.groups.remove(item.group)
    }
  }

  /**
   * This is needed to prevent double clicking on the edges of a booked time from opening a new booked time form
   */
  _invalidBookedTimeSpot(item) {
    const otherItems = this.items.get().filter(i => i.group === item.group)

    return otherItems.some(otherItem => doRangesOverlap(otherItem.start, otherItem.end, item.start, moment(item.start).clone().add(1, 'week')))
  }

  _renderGroupTemplate(group, _div) {
    const template = new RoleCallGroupTemplate(group)
    return template.render()
  }

  /**
   * This prevents the groups from collapsing when clicking the action buttons within them
   * @private
   */
  _handleGroupVisibility() {
    this.timeline.itemSet.groupHammer.off('tap')
    this.timeline.itemSet.groupHammer.on('tap', async event => {
      let target = event.target

      if (target.className === 'material-symbols-outlined' || target.className === 'btn') {
        return
      }

      this.timeline.itemSet._onGroupClick(event)

      await this.updateCollapseSettingOnServer()
    })
  }

  async updateCollapseSettingOnServer() {
    setTimeout(async () => {
      const collapsedIds = Object.values(this.timeline.itemSet.groups)
        .filter(g => !g.showNested && Boolean(g.nestedGroups)).map(g => g.groupId)
      const name = this._personView() ? 'role_call_collapsed_person_ids' : 'role_call_collapsed_project_ids'
      return this.apiClient().fetch(`settings/${name}`, 'PUT', { value: collapsedIds })
    }, 200)
  }

  _customSnap(date, _scale, _step) {
    const day = 60 * 60 * 1000 * 12
    return moment(new Date(Math.round(date / day) * day)).startOf('day')
  }

  _personView() {
    return this.__personView ??= new URLSearchParams(window.location.search).get('person_view') === 'true'
  }

  async _updateSummaryItems(group) {
    const summaryItems = await this.bookedTimesController.summaryItems(group)

    if (summaryItems.length > 0) {
      const persistedItemIds = summaryItems.map(i => i.id)
      const parentId = summaryItems[0].group
      const itemsToRemove = this.items.get().filter(i => i.className === 'project-summary' && i.group === parentId && !persistedItemIds.includes(i.id))
      itemsToRemove.forEach(i => this.items.remove(i.id))
    }

    summaryItems.forEach(summaryItem => {
      const existing = this.items.get(summaryItem.id)

      if (existing) {
        this.items.updateOnly({ id: summaryItem.id, content: summaryItem.content, className: summaryItem.className })
      } else {
        this.items.add(summaryItem)
      }
    })
  }

  _options() {
    return {
      onMove: this._moveBookedTime.bind(this),
      onAdd: this._onAddBookedTime.bind(this),
      onUpdate: this._updateBookedTime.bind(this),
      onRemove: this._deleteBookedTime.bind(this),
      orientation: "top",
      snap: this._customSnap.bind(this),
      editable: this.isEditable,
      dataAttributes: ['id'],
      multiselect: true,
      groupEditable: true,
      verticalScroll: true,
      maxHeight: '100vh',
      groupTemplate: this._renderGroupTemplate.bind(this),
      stack: false,
      showCurrentTime: true,
      zoomKey: 'shiftKey',
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('quarter').format('YYYY-MM-DD'),
      timeAxis: { scale: 'week', step: 1 },
      tooltip: {
	template: function (originalItemData, parsedItemData) {
	  return originalItemData.tooltip;
	},
      },
      locale: 'en',
      format: {
        minorLabels: {
          week: 'ddd D',
        }
      }
    }
  }
}
