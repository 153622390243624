import React from 'react';

const WEEK_MULTIPLIER = 5;
const MONTH_MULTIPLIER = 21.66;

class TimePerPeriodInput extends React.Component {
  static defaultProps = {
    hoursPerWeekday: 0
  }

  constructor(props) {
    super(props);
    const hoursPerWeekday = props.hoursPerWeekday === '' ? 0 : props.hoursPerWeekday
    this.state = {
      hoursPerWeekday,
      hoursPerWeek: (props.hoursPerWeekday * WEEK_MULTIPLIER).toFixed(2),
      hoursPerMonth: (props.hoursPerWeekday * MONTH_MULTIPLIER).toFixed(2)
    };
  }

  weekdayChange(event) {
    const value = event.target.value
    this.setState(() => {
      return {
        hoursPerWeekday: value,
        hoursPerWeek: (value * WEEK_MULTIPLIER).toFixed(2),
        hoursPerMonth: (value * MONTH_MULTIPLIER).toFixed(2)
      }
    });
  }

  weekChange(event) {
    const value = event.target.value
    this.setState(() => {
      return {
        hoursPerWeekday: (value / WEEK_MULTIPLIER).toFixed(1),
        hoursPerWeek: value,
        hoursPerMonth: (value / WEEK_MULTIPLIER) * MONTH_MULTIPLIER
      }
    });
  }

  monthChange(event) {
    const value = event.target.value
    this.setState(() => {
      return {
        hoursPerWeekday: (value / MONTH_MULTIPLIER).toFixed(1),
        hoursPerWeek: ((value / MONTH_MULTIPLIER) * WEEK_MULTIPLIER).toFixed(2),
        hoursPerMonth: value
      }
    });
  }

  render() {
    let hoursPerWeekday = this.state.hoursPerWeekday;
    let hoursPerWeek = this.state.hoursPerWeek;
    let hoursPerMonth = this.state.hoursPerMonth;
    return (
      <div>
        <div className="form__group">
          <label htmlFor="booked_time_hours_per_weekday" className="form__label">
            Hours/Weekday
          </label>
          <input
            id="booked_time_hours_per_weekday"
            data-testid="booked_time_hours_per_weekday"
            name="booked_time[hours_per_weekday]"
            type="number"
            step="0.01"
            max="12"
            value={hoursPerWeekday}
            className="form__input"
            onChange={this.weekdayChange.bind(this)}
           />
        </div>
        <div className="form__field">
          <label htmlFor="booked_time_hours_per_week" className="form__label">
            Hours/Week
          </label>
          <input
            id="booked_time_hours_per_week"
            data-testid="booked_time_hours_per_week"
            name="booked_time[hours_per_week]"
            type="number"
            step="0.01"
            max="60"
            value={hoursPerWeek}
            className="form__input"
            onChange={this.weekChange.bind(this)}
           />
        </div>
        <div className="form__group">
          <label htmlFor="booked_time_hours_per_month" className="form__label">
            Hours/Month
          </label>
          <input
            id="booked_time_hours_per_month"
            data-testid="booked_time_hours_per_month"
            name="booked_time[hours_per_month]"
            type="number"
            step="0.01"
            max="250"
            value={hoursPerMonth}
            className="form__input"
            onChange={this.monthChange.bind(this)}
           />
        </div>
      </div>
    );
  }
}

export default TimePerPeriodInput;
