import { Controller } from '@hotwired/stimulus'

class QuickFilterController extends Controller {
  static values = {
    targetOnFilter: { type: Boolean, default: false },
    activeClass: { type: String, default: 'active' }, // The class to apply to the active quick filter
    hiddenClass: { type: String, default: 'hidden' } // The class to apply to hidden things
  }
  static targets = ['quickFilter', 'filteredContent']

  initialize() {
    this._showContent()
  }

  toggle(event) {
    const relevantElement = this.targetOnFilterValue ? event.target : event.target.parentNode
    this.index = this.quickFilterTargets.indexOf(relevantElement)
  }

  _showContent() {
    this.quickFilterTargets.forEach((element, i) => {
      const relevantElement = this.targetOnFilterValue ? element : element.childNodes[0]
      relevantElement.classList.toggle(this.activeClassValue, i === this.index)
    })

    this.filteredContentTargets.forEach((element, i) => element.childNodes[0].classList.toggle(this.hiddenClassValue, i !== this.index))
  }

  get index() {
    return parseInt(this.data.get('index'))
  }

  set index(value) {
    this.data.set("index", value)
    this._showContent()
  }
}

export default QuickFilterController
