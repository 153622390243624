import { Controller } from '@hotwired/stimulus'

export default class FilterGroupController extends Controller {
  static targets = [
    'filter',
    'clearFiltersLink',
    'addFilter',
    'secondaryAddFilter',
    'filterContainer',
    'savedFiltersLabel',
    'saveFilterLink'
  ]

  async addFilter(event) {
    event.preventDefault()

    const filterId = `${event.target.dataset.filter}-filter`
    const filterToEnable = document.getElementById(filterId)
    const enableEvent = new CustomEvent('enable')
    filterToEnable.dispatchEvent(enableEvent)
  }

  updateFilterGroupControls() {
    if (Array.from(this.filterTargets).some(filter => !filter.dataset.disabled)) {
      this.addFilterTarget.classList.add('hidden')
      this.secondaryAddFilterTarget.classList.remove('hidden')
      this.clearFiltersLinkTarget.classList.remove('hidden')
      this.filterContainerTarget.classList.remove('hidden')

      this.updateAvailableFilters()
    } else {
      this.addFilterTarget.classList.remove('hidden')
      this.secondaryAddFilterTarget.classList.add('hidden')
      this.clearFiltersLinkTarget.classList.add('hidden')
      this.filterContainerTarget.classList.add('hidden')
    }
  }

  updateAvailableFilters() {
    const menuItems = this.secondaryAddFilterTarget.querySelectorAll('sl-menu-item')
    let usedFilterCount = 0

    menuItems.forEach(menuItem => {
      const filterId = `${menuItem.dataset.filter}-filter`
      const containingButton = menuItem.closest('button')
      const filterContainer = document.getElementById(filterId).closest('.filter')

      if (filterContainer.classList.contains('hidden')) {
        containingButton.classList.remove('hidden')
      } else {
        containingButton.classList.add('hidden')
        usedFilterCount += 1
      }
    })

    if (usedFilterCount === menuItems.length) {
      this.secondaryAddFilterTarget.classList.add('hidden')
    } else {
      this.secondaryAddFilterTarget.classList.remove('hidden')
    }
  }

  resetSavedFiltersLabelText() {
    if (this.hasSavedFiltersLabelTarget) {
      this.savedFiltersLabelTarget.innerText = 'Saved Views'
      this.saveFilterLinkTarget.classList.remove('hidden')
    }
  }

  controlGroupChange() {
    this.updateFilterGroupControls()
  }

  filterTargetConnected() {
    this.updateFilterGroupControls()
  }
}
