import { Controller } from '@hotwired/stimulus'
import React from 'react'
import { createRoot } from 'react-dom/client'

import BudgetTrendsLineChart from "../components/BudgetTrendsLineChart.jsx";
import CadenceChart from "../components/CadenceChart.jsx";
import CircleProgress from "../components/CircleProgress.jsx";
import PaceChart from "../components/PaceChart.jsx";
import ReportSelection from "../components/ReportSelection.jsx";
import ReportTimeRangeSelection from "../components/ReportTimeRangeSelection.jsx";
import TimePerPeriodInput from "../components/TimePerPeriodInput.jsx";

const registeredComponents = {
  BudgetTrendsLineChart,
  CadenceChart,
  CircleProgress,
  PaceChart,
  ReportSelection,
  ReportTimeRangeSelection,
  TimePerPeriodInput,
}

export default class extends Controller {
  connect() {
    const componentName = this.element.dataset.component
    const component = registeredComponents[componentName]

    if (component) {
      const root = createRoot(this.element)
      const props = JSON.parse(this.element.dataset.props)
      root.render(React.createElement(component, props))
    } else {
      throw new Error('Unrecognized React component name!')
    }
  }
}
