import { Controller } from '@hotwired/stimulus'

class ClipboardController extends Controller {
  async copy(_event) {
    const clipboardIcon = this.element.querySelector('.copy-to-clipboard-icon')
    const loadingCircle = this.element.querySelector('.loading-circle')
    const checkmark = this.element.querySelector('.checkmark')

    this.toggle(clipboardIcon)
    this.toggle(loadingCircle)

    const url = this.element.getAttribute('data-url')
    const text = this.element.getAttribute('data-text')

    if (url) {
      const response = await fetch(url)
      const data = await response.text()
      await navigator.clipboard.writeText(data)
    }

    if (text) {
      await navigator.clipboard.writeText(text)
    }

    loadingCircle.classList.add('load-complete')
    this.toggle(checkmark)
    checkmark.classList.add('draw')

    setTimeout(() => {
      this.toggle(loadingCircle)
      this.toggle(checkmark)
      this.toggle(clipboardIcon)
    }, 6000)
  }

  toggle(element) {
    if (element.classList.contains('hidden')) {
      return element.classList.remove('hidden')
    }
    element.classList.add('hidden')
  }
}

export default ClipboardController
