import { Controller } from '@hotwired/stimulus'
import Rails from '@rails/ujs'
import { toggleVisible, toggleDisplayed, animate } from '../helpers/dom_helpers'

class TimeOffPlannerSidebarController extends Controller {
  static targets = [
    'timeOffDateForm',
    'timeOffSection',
    'timeOffDateFormContainer',
    'timeOffDates',
    'newTimeOffDateButton',
    'breakdown',
    'breakdownChevron',
  ]

  connect() {
    if (this.timeOffDateFormContainer) {
      this.adjustTimeOffDatesContainerHeight()
    }
  }

  showTimeOffForm(_event) {
    toggleVisible(this.timeOffDateFormContainerTarget, true)
    this.adjustTimeOffDatesContainerHeight()

    this.timeOffDatesTarget.style.transform = `translateY(${this.adjustHeight()}px)`

    animate(this.newTimeOffDateButtonTarget, 'fadeOut')
    animate(this.timeOffDateFormContainerTarget, 'slideInRight')

    setTimeout(() => {
      toggleVisible(this.newTimeOffDateButtonTarget, false)
    }, 700)
  }

  hideTimeOffForm(_event) {
    animate(this.timeOffDateFormContainerTarget, 'slideOutRight')
    toggleVisible(this.newTimeOffDateButtonTarget, true)
    animate(this.newTimeOffDateButtonTarget, 'fadeIn')

    setTimeout(() => {
      toggleVisible(this.timeOffDateFormContainerTarget, false)
      this.timeOffDatesTarget.style.transform = 'translateY(0)'
    }, 700)

    setTimeout(() => {
      this.timeOffDatesTarget.style.transform = 'translateY(0)'
    }, 300)
  }

  submitTimeOffForm(_event) {
    this.hideTimeOffForm()
    setTimeout(() => Rails.fire(this.timeOffDateFormTarget, 'submit'), 700)
  }

  adjustTimeOffDatesContainerHeight() {
    const height = this.timeOffSectionTarget.getBoundingClientRect().height
    const newHeight = height + this.adjustHeight() // make room for animated form
    this.timeOffSectionTarget.style.height = newHeight + 'px'
  }

  toggleConstraintTimeFields(_event) {
    this.adjustTimeOffDatesContainerHeight()
    this.timeOffDatesTarget.style.transform = `translateY(${this.adjustHeight()}px)`
  }

  adjustHeight() {
    return this.timeOffDateFormContainerTarget.getBoundingClientRect().height + 30
  }

  toggleBreakdown() {
    toggleDisplayed(this.breakdownTarget)

    if (this.breakdownTarget.classList.contains('hidden')) {
      this.breakdownChevronTarget.style.transform = ''
    } else {
      this.breakdownChevronTarget.style.transform = 'rotate(180deg)'
    }
  }
}

export default TimeOffPlannerSidebarController
