import ApiClient from './ApiClient'

class BookedTimesController {
  constructor(timeline) {
    this._timeline = timeline
  }

  get baseUrl() {
    return `/booked_times`
  }

  get timeline() {
    return this._timeline
  }

  set timeline(newTimeline) {
    this._timeline = newTimeline
  }

  apiClient() {
    return this._apiClient ??= new ApiClient()
  }

  async index(start_date, end_date) {
    const urlParams = new URLSearchParams(window.location.search)
    const personView = urlParams.get('person_view');
    let url = `${this.baseUrl}?person_view=${personView}`
    if (start_date) url += `&start_date=${start_date.toDateString()}`
    if (end_date) url += `&end_date=${end_date.toDateString()}`
    const response = await this._fetch(url)
    return response.json()
  }

  async summaryItems(group) {
    const urlParams = new URLSearchParams(window.location.search)
    const personView = urlParams.get('person_view');
    const url = `${this.baseUrl}/summary_items?group=${group}&person_view=${personView}`
    const response = await this._fetch(url)
    return response.json()
  }

  async edit(item, hoursPerWeek) {
    const [personId, projectId] = item.group.split('-')
    if (!personId || !projectId) return
    const url = `${this.baseUrl}/${item.id}`

    const body = {
      start_date: item.start,
      end_date: item.end,
      person_id: personId,
      project_id: projectId,
      hours_per_weekday: parseFloat(hoursPerWeek) / 5,
    }

    return this._fetch(url, 'PUT', body)
  }

  async update(item) {
    const [personId, projectId] = item.group.split('-')
    if (!personId || !projectId) return
    const url = `${this.baseUrl}/${item.id}`

    const body = {
      start_date: item.start,
      end_date: item.end,
      person_id: personId,
      project_id: projectId,
    }

    return this._fetch(url, 'PATCH', body)
  }

  async add(item, hoursPerWeek) {
    const [personId, projectId] = item.group.split('-')
    if (!personId || !projectId) return
    const body = {
      start_date: item.start,
      hours_per_weekday: parseFloat(hoursPerWeek) / 5,
      person_id: personId,
      project_id: projectId,
    }

    return this._fetch(this.baseUrl, 'POST', body)
  }

  async delete(item) {
    const [personId, projectId] = item.group.split('-')
    if (!personId || !projectId) return
    const url = `${this.baseUrl}/${item.id}`
    const body = {
      person_id: personId,
      project_id: projectId,
      turbo: true
    }

    return this._fetch(url, 'DELETE', body)
  }

  async split(bookedTimeId, ratio) {
    if (!bookedTimeId || !ratio) return

    const url = `${this.baseUrl}/${bookedTimeId}/split`
    const body = { ratio }
    return this._fetch(url, 'POST', body)
  }

  async _fetch(url, method = 'GET', body) {
    return this.apiClient().fetch(url, method, body)
  }
}

export default BookedTimesController
