import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['fileText', 'imageInput', 'imagePreview']

  updateText(event) {
    const fileName = event.target.value.split('\\').pop()
    this.fileTextTarget.innerHTML = this.truncate(fileName)
  }

  readImageURL() {
    const input = this.imageInputTarget
    const output = this.imagePreviewTarget

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
        output.classList.remove('visibility-none')
      }

      reader.readAsDataURL(input.files[0]);
    }
  }

  truncate(string) {
    if (string.length < 33) { return string }

    const start = string.substr(0, 20)
    const end = string.substr(string.length - 10, 10)
    return `${start}...${end}`
  }
}
