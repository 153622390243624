import { Controller } from '@hotwired/stimulus'

class CandidateScoreController extends Controller {
  static targets = ['scoreBlock']
  static values = {
    score: Number
  }

  connect() {
    super.connect()
    this._fillScoreBlocks()
  }

  _fillScoreBlocks() {
    const minBlockCount = 1
    const maxBlockCount = 6
    const blockCount = Math.min(Math.max(Math.floor(this.scoreValue / 100), minBlockCount), maxBlockCount)
    for (let step = 0; step < blockCount; step++) {
      this.scoreBlockTargets[step].classList.add('filled')
    }
  }
}

export default CandidateScoreController
