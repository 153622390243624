import Chart from 'stimulus-chartjs'

const formattedDate = date => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  const correctDate = new Date(date.getTime() + userTimezoneOffset)
  return `${correctDate.toLocaleString('default', { month: 'short' })} ${correctDate.getDate()}, ${correctDate.getFullYear()}`
}

class DateChartController extends Chart {
  static values = {
    data: Object,
    options: Object,
    type: { type: String, default: 'line' },
    lineColors: Array,
    labelColor: String
  }

  connect() {
    super.connect()
    this._setLineColor()
  }

  _setLineColor() {
    this.lineColorsValue.forEach((lineColor, index) => {
      const color = this._getColorValue(lineColor)
      this.chart.data.datasets[index].borderColor = color
      this.chart.data.datasets[index].backgroundColor = color
      this.chart.update()
    })
  }

  get defaultOptions() {
    return {
      maintainAspectRatio: false,
      scales: this._scales(),
      plugins: {
        legend: {
          display: this._showLegend(),
          labels: { color: this._labelColor() }
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: this._tooltipTitle,
            label: this._tooltipLabel,
            afterBody: this._tooltipAfterBody.bind(this)
          }
        }
      }
    }
  }

  _scales() {
    return {
      x: {
        ticks: {
          maxTicksLimit: 12,
          callback: this._xTickLabel,
          color: this._labelColor()
        }
      },
      y: {
        type: 'linear',
        display: true,
        position: 'left',
        min: 0,
        max: 5,
        ticks: {
          maxTicksLimit: 6,
          color: this._labelColor()
        },
      }
    }
  }

  _showLegend() { return true }

  _xTickLabel(value) { return formattedDate(new Date(this.getLabelForValue(value))) }

  _tooltipTitle(tooltipItem) {
    const pulseDate = tooltipItem[0].label

    const startDate = new Date(pulseDate)
    startDate.setDate(startDate.getDate() - 6)

    const endDate = new Date(pulseDate)

    return `${formattedDate(startDate)} - ${formattedDate(endDate)}`
  }

  _tooltipLabel(tooltipItem) {
    return `${tooltipItem.dataset.label}: ${tooltipItem.formattedValue}`
  }

  _tooltipAfterBody(_tooltipItem) { return '' }

  _labelColor() { return this._getColorValue(this.labelColorValue) }

  _getColorValue(property) {
    return getComputedStyle(document.documentElement).getPropertyValue(property)
  }

}

export default DateChartController
