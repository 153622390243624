import { Controller } from '@hotwired/stimulus'

class LoadingSpinnerController extends Controller {
  static targets = [
    'wrapper'
  ]

  renderSpinner() {
    this.wrapperTarget.style.position = 'relative'
    this.wrapperTarget.appendChild(this._spinnerNode())
  }

  _spinnerNode() {
    const spinner = document.createElement('div')
    spinner.classList.add('spinner')
    return spinner
  }
}

export default LoadingSpinnerController
