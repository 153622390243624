import PropTypes from 'prop-types'
import React from 'react'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryLine } from 'victory'

export default class PaceChart extends React.Component {
  static propTypes = {
    source: PropTypes.object
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { target, worked_data, banked_data } = this.props.source
    const offset = worked_data[0].y + banked_data[0].y

    const colors = {
      base: 'var(--color-chart)',
      dark: 'var(--color-chart-dark)',
      light: 'var(--color-chart-light)',
    }

    return (
      <VictoryChart
        horizontal
        height={60}
        domain={{ x: [-1, 0], y: [0, target] }}
        padding={0}
      >
        <VictoryStack>
          <VictoryBar
            style={{
              data: { fill: colors.base }
            }}
            barWidth={80}
            data={worked_data}
          />
          <VictoryBar
            style={{
              data: { fill: colors.dark }
            }}
            barWidth={80}
            data={banked_data}
          />
          <VictoryBar
            style={{
              data: { fill: colors.light }
            }}
            barWidth={80}
            data={[{ x: 0, y: target }]}
          />
        </VictoryStack>
        <VictoryLine
          style={{
            data: { stroke: colors.light, strokeWidth: 1 },
            parent: { border: `1px solid ${colors.light}` }
          }}
          data={[
            { x: 0, y: offset },
            { x: -0.8, y: target / 2 },
            { x: -0.8, y: offset },
            { x: -1, y: target / 2 },
          ]}
        />

        <VictoryAxis
          tickFormat={() => ''}
          style={{
            axis: { stroke: colors.base, strokeWidth: 2 }
          }}
        />
        <VictoryAxis
          tickFormat={() => ''}
          orientation='right'
          style={{
            axis: { stroke: colors.light, strokeWidth: 2 }
          }}
        />
      </VictoryChart>
    )
  }
}
