import Rails from '@rails/ujs'
import TomSelectController from './tom_select_controller'
import TomSelect from 'tom-select'

class ScoreController extends TomSelectController {
  static targets = [
    'form',
    'dropdown', // used by TomSelectController
  ]
  static values = {
    allowCustom: Boolean,
    metricId: Number,
    createCustomOptionPath: String,
    sortField: String, // used by TomSelectController
  }

  connect() {
    this.tomSelect = new TomSelect(this.dropdownTarget, {
      closeAfterSelect: true,
      sortField: {
        field: this.sortFieldValue,
        direction: 'asc',
      },
      ...this.extendedSettings ?? {},
    })
  }

  get extendedSettings() {
    return {
      create: this.allowCustomValue && this.customOption.bind(this),
      maxItems: 1,
    }
  }

  changeScore(event) {
    if (event.target.value) {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  customOption(value, callback) {
    const data = new URLSearchParams({
      'scorecard_metric_option[scorecard_metric_id]': this.metricIdValue,
      'scorecard_metric_option[name]': value,
    }).toString()
    Rails.ajax({
      type: 'POST',
      url: this.createCustomOptionPathValue,
      dataType: 'json',
      data,
      success: (response) => {
        callback({ value: response.id, text: response.name })
      }
    })
  }
}

export default ScoreController
