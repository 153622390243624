import { Controller } from '@hotwired/stimulus'
import { setFsaExpenses, getFsaExpenses, showFlashMessage } from '../helpers/fsaExpenses'
import uuid from 'uuid-random'

class FsaForm extends Controller {
  static targets = ['description', 'price', 'frequency', 'timeframe', 'link']
  static values = { key: String }

  add() {
    const validations = [this._validatePresence(this.descriptionTarget), this._validateNumber(this.priceTarget), this._validateNumber(this.frequencyTarget)]

    if (validations.every((validation) => validation)) {
      const almanacData = getFsaExpenses()

      almanacData.push({
        key: uuid(),
        description: this.descriptionTarget.value,
        price: this.priceTarget.value,
        frequency: this.frequencyTarget.value,
        timeframe: this.timeframeTarget.value,
        link: this.linkTarget.value,
      })

      setFsaExpenses(almanacData)
      window.location = '/fsa_expenses'
    }
  }

  connect() {
    if (this.keyValue) {
      this.prefillForm(this.keyValue)
    }
  }

  prefillForm(key) {
    const almanacData = getFsaExpenses()
    const expenseData = almanacData.filter((row) => row.key === key)[0]
    this.descriptionTarget.value = expenseData.description
    this.priceTarget.value = expenseData.price
    this.frequencyTarget.value = expenseData.frequency
    this.timeframeTarget.value = expenseData.timeframe
    this.linkTarget.value = expenseData.link
  }

  update() {
    const validations = [this._validatePresence(this.descriptionTarget), this._validateNumber(this.priceTarget), this._validateNumber(this.frequencyTarget)]

    if (validations.every((validation) => validation)) {
      const almanacData = getFsaExpenses()
      const newExpenses = almanacData.map((row) => {
        if (row.key !== this.keyValue) return row

        return {
          key: this.keyValue,
          description: this.descriptionTarget.value,
          price: this.priceTarget.value,
          frequency: this.frequencyTarget.value,
          timeframe: this.timeframeTarget.value,
          link: this.linkTarget.value,
        }
      })

      setFsaExpenses(newExpenses)
      window.location = '/fsa_expenses'
    }
  }

  _validatePresence(input) {
    if (input.value.trim() === '') {
      input.nextSibling.textContent = 'must be present'
      input.nextSibling.classList.remove('hidden')
      return false
    }

    input.nextSibling.textContent = ''
    input.nextSibling.classList.add('hidden')
    return true
  }

  _validateNumber(input) {
    if (isNaN(input.value.trim()) || input.value.trim() === '') {
      input.nextSibling.textContent = 'must be a number'
      input.nextSibling.classList.remove('hidden')
      return false
    }

    input.nextSibling.textContent = ''
    input.nextSibling.classList.add('hidden')
    return true
  }
}

export default FsaForm
