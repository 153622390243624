import StimulusDropdownController from 'stimulus-dropdown'
import { useTransition } from 'stimulus-use'

export default class ContextMenuController extends StimulusDropdownController {
  connect() {
    if (!this.enter) {
      useTransition(this, {
        element: this.menuTarget,
        hiddenClass: this.hiddenClass,
        transitioned: false
      })
    }
  }

  get hiddenClass() { return 'context-menu__dropdown--closed' }

  hide(event) {
    if (!this.element.contains(event.target) && !this.menuTarget.classList.contains(this.hiddenClass)) {
      this.leave()
    }
  }

  formSubmission(event) {
    if (event.detail.success) {
      this.toggle()
    }
  }
}
