export const setFsaExpenses = (value) => {
  localStorage.setItem('almanac', JSON.stringify({ fsaExpenses: value }))
}

export const getFsaExpenses = () => {
  return JSON.parse(localStorage.getItem('almanac'))?.['fsaExpenses'] || []
}

export const showFlashMessage = (msg, type) => {
  const iconTypes = { notice: 'check_circle', success: 'check_circle', alert: 'cancel' }
  const iconName = iconTypes[type] || type

  const flash = `
  <div class="flash-container">
    <div class="flash flash--${type}">
      <span class="material-icons">${iconName}</span>
        <div class="margin-left-md">
          <div class="flash__message-type">${type == 'notice' ? 'Success' : type}</div>
          <div class="flash__message">${msg}</div>
        </div>
      </div>
    </div>
  `

  document.getElementById('flash').innerHTML = flash
}
