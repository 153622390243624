import { Controller } from '@hotwired/stimulus'

class ThemePickerController extends Controller {
  static targets = ['value']

  mouseIn(event) {
    this.valueTarget.value = event.target.dataset.colorValue
  }

  mouseOut() {
    this.valueTarget.value = undefined
  }
}

export default ThemePickerController
