import PropTypes from 'prop-types'
import React from 'react'
import { VictoryAnimation, VictoryLabel, VictoryPie } from 'victory'

export default class CircleProgress extends React.PureComponent {
  static propTypes = {
    percent: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      data: this.getData()
    };
  }

  getData() {
    const { percent } = this.props
    return [{ x: 1, y: parseInt(percent) }, { x: 2, y: 100 - parseInt(percent) }];
  }

  // Consistent with project_report.rb
  getColor(percent) {
    if (percent >= 40) { return '#27AE60' }
    if (percent >= 25) { return '#AEAD33' }
    if (percent >= 10) { return '#F39C12' }
    return '#E74C3C'
  }

  render() {
    return (
      <div>
        <svg viewBox="0 20 400 350" width="100%" height="100%">
          <VictoryPie
            standalone={false}
            animate
            width={400} height={400}
            data={this.state.data}
            innerRadius={120}
            cornerRadius={25}
            labels={() => null}
            style={{
              data: { fill: ({ datum }) => {
                  const color = this.getColor(datum.y)
                  return datum.x === 1 ? color : "transparent"
                }
              }
            }}
          />
          <VictoryLabel
            textAnchor="middle" verticalAnchor="middle"
            x={200} y={200}
            text={`${Math.round(this.props.percent)}%`}
            style={{ fontSize: 60, fill: 'var(--color-on-primary)' }}
          />
        </svg>
      </div>
    );
  }
}
