import PropTypes from 'prop-types'
import React from 'react'
import {
  VictoryAxis,
  VictoryChart,
  VictoryGroup, VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryTooltip,
} from 'victory'

export default class BudgetTrendsLineChart extends React.Component {
  static propTypes = {
    data: PropTypes.array
  }

  constructor(props) {
    super(props)
  }

  chartColor() {
    return 'var(--color-on-primary-container-dark)'
  }

  lineColors() {
    return [
      'var(--color-chart-blue)',
      'var(--color-chart-green)',
      'var(--color-chart-purple)'
    ]
  }

  formatDollars(number) {
    return `$${Math.round(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
  }

  renderLines() {
    return this.props.data.map((line, index) => {
      return (
        <VictoryGroup
          key={line.name}
          color={this.lineColors()[index]}
          labels={({ datum }) => this.formatDollars(datum.y)}
          scale={{ x: "time", y: "linear" }}
          labelComponent={<VictoryTooltip style={{ fontSize: 6 }} />}
          data={line.data.map((point => ({ x: new Date(point.x), y: parseInt(point.y) })))}
        >
          <VictoryLine interpolation="monotoneX"/>
          <VictoryScatter size={3}/>
        </VictoryGroup>
      )
    })
  }

  renderMainAxis() {
    return (
      <VictoryAxis
        independentAxis
        orientation='bottom'
        fixLabelOverlap={true}
        style={{
          axis: { stroke: this.chartColor(), strokeWidth: 2 },
          tickLabels: { angle: 45, strokeWidth: .5, fill: this.chartColor(), fontSize: 6 }
        }}
      />
    )
  }

  renderCrossAxis() {
    return (
      <VictoryAxis
        tickFormat={(t) => this.formatDollars(t)}
        orientation='left'
        dependentAxis
        style={{
          axis: { stroke: this.chartColor(), strokeWidth: 2 },
          tickLabels: { strokeWidth: .4, fill: this.chartColor(), fontSize: 8 }
        }}
      />
    )
  }

  renderLegend() {
    const legendItems = this.props.data.map((line, index) => ({ name: line.name, symbol: { fill: this.lineColors()[index] } }))
    return <VictoryLegend x={60} y={0} symbolSpacer={5} orientation="horizontal" style={{ labels: { fontSize: 8, fill: this.chartColor() } }} data={legendItems}/>
  }

  render() {
    return (
      <VictoryChart height={250} scale={{ x: "time" }}>
        {this.renderLegend()}
        {this.renderMainAxis()}
        {this.renderCrossAxis()}
        {this.renderLines()}
      </VictoryChart>
    )
  }
}
