import { Controller } from '@hotwired/stimulus'

export default class ClientHoursProgressController extends Controller {
  static targets = ['relevantHours', 'outdatedHours']
  static values = {
    relevantHours: Number,
    outdatedHours: Number,
    score: Number,
    goal: Number,
  }

  connect() {
    super.connect()
    this._setProgressValues()
  }

  _setProgressValues() {
    const score = this.scoreValue
    const relevantHours = this.relevantHoursValue
    const outdatedHours = this.outdatedHoursValue
    const totalHours = relevantHours + outdatedHours
    const scorePercent = (score / this.goalValue) * 100
    const totalProgressPercent = Math.min(scorePercent, 100)
    const relevantProgressPercent = (relevantHours / totalHours) * totalProgressPercent
    const outdatedProgressPercent = (outdatedHours / totalHours) * totalProgressPercent

    this.relevantHoursTarget.style.width = `${relevantProgressPercent}%`
    this.outdatedHoursTarget.style.width = `${outdatedProgressPercent}%`
  }
}
