import { toggleVisible, setElementLocation, animate } from '../helpers/dom_helpers'

/**
 * This class controls the ability to add notes to a day in the time off planner calendar view.
 * The javascript here is interacting with two pieces of html. There is a single note partial that gets rendered on the page
 * and there is meta data on each day that is used to populate the note form. This class takes the data from the specific
 * day and inserts the correct data into the form. This class also adds animations to enhance the UI.
 */
export default class TimeOffPlannerNoteForm {
  constructor(selectedDayNode) {
    this._selectedDayNode = selectedDayNode
  }

  get noteContainerClass() { return '.note-container' }
  get inputClass() { return '.note-container-input' }
  get selectedDayNodeClass() { return '.time-off-planner__day' }
  get noteDateClass() { return '.note-date' }
  get noteSaveButtonClass() { return '.note-save-button' }
  get noteTitleClass() { return '.note-title' }

  get selectedDayNode() {   return this._selectedDayNode }
  get noteContainerNode() { return document.querySelector(this.noteContainerClass) }
  get noteInput() {         return document.querySelector(this.inputClass) }
  get noteDateNode() {      return document.querySelector(this.noteDateClass) }
  get noteSaveButton() {    return document.querySelector(this.noteSaveButtonClass) }
  get noteTitleNode() {     return document.querySelector(this.noteTitleClass) }
  get dateFormField() {     return document.querySelector('#note-date-hidden-field').value }
  get noteIndicator() {     return document.querySelector(`[data-date='${this.dateFormField}']`).nextElementSibling }
  get storedDate() {        return this.selectedDayNode.dataset.date }
  get storedNoteText() {    return this.selectedDayNode.dataset.note }

  set dateFormField(value) { return document.querySelector('#note-date-hidden-field').value = value }

  textChanged() { return this.noteInput.value !== this.storedNoteText }

  updateSelectedDayNode(selectedDayNode) {
    this._selectedDayNode = selectedDayNode
  }

  onInputChange() {
    this._updateNoteSaveButton()
  }

  show() {
    this._addEventListeners()
    this._deselectAllDays()

    if (this.selectedDayNode.classList.contains('selected')) {
      toggleVisible(this.noteContainerNode, false)
      return this.selectedDayNode.classList.remove('selected')
    }

    this._setPosition()

    animate(this.noteContainerNode, 'bounceIn')
    toggleVisible(this.noteContainerNode, true)
    toggleVisible(this.noteTitleNode, true)

    this.noteDateNode.innerHTML = this.storedDate
    this.dateFormField = this.storedDate
    this.selectedDayNode.classList.add('selected')
    this.noteInput.value = this.storedNoteText === undefined ? '' : this.storedNoteText
  }

  submit() {
    this._close(true)
  }

  _setPosition() {
    const { x, y, top } = this.selectedDayNode.getBoundingClientRect()
    const windowHeight = document.body.clientHeight
    const noteHeight = 200
    if ((top + noteHeight) >= windowHeight) {
      return setElementLocation(this.noteContainerNode, x, y - noteHeight)
    }
    setElementLocation(this.noteContainerNode, x, y + 30)
  }

  _close(submitButtonClicked = false) {
    this.removeEventListeners()
    this._deselectAllDays()
    animate(this.noteContainerNode, 'bounceOut')
    this._updateNoteSaveButton(false)
    setTimeout(() => { toggleVisible(this.noteContainerNode, false) }, 500)
    if (submitButtonClicked && this.textChanged()) this._reset()
  }

  removeEventListeners() {
    document.removeEventListener('mousedown', this._handleOutsideClick, true)
    document.removeEventListener('scroll', this._handleScroll, true)
    this._handleScroll = undefined
    this._handleOutsideClick = undefined
  }

  _addEventListeners() {
    if (!this._handleOutsideClick) {
      this._handleOutsideClick = (event) => this._handleOutsideNoteFormClick(event)
      document.addEventListener('mousedown', this._handleOutsideClick, true)
    }
    if (!this._handleScroll) {
      this._handleScroll = (event) => this._setPosition(event)
      document.addEventListener('scroll', this._handleScroll, true)
    }
  }
  _updateNoteSaveButton(override) {
    if (override ?? this.textChanged()) {
      if (!this.noteSaveButton.classList.contains('visibility-hidden')) return
      toggleVisible(this.noteSaveButton, true)
      toggleVisible(this.noteTitleNode, false)
      animate(this.noteSaveButton, 'headShake')
    } else {
      toggleVisible(this.noteSaveButton, false)
    }
  }

  _reset() {
    this.selectedDayNode.dataset.note = this.noteInput.value
    this.noteDateNode.value = ''
    toggleVisible(this.noteIndicator, this.noteInput.value !== '')
  }

  _handleOutsideNoteFormClick(event) {
    const clickOutsideNoteContainer = !event.target.closest(this.noteContainerClass)
    const notClickingAnotherDay = !event.target.classList.contains(this.selectedDayNodeClass.replace('.', ''))
    const noteContainerIsVisible = !this.noteContainerNode.classList.contains('visibility-hidden')

    if (clickOutsideNoteContainer && notClickingAnotherDay && noteContainerIsVisible) {
      this._close()
    }
  }

  _deselectAllDays() {
    document.querySelectorAll(`${this.selectedDayNodeClass}.selected`).forEach(day => day.classList.toggle('selected', false))
  }
}
