import { Controller } from '@hotwired/stimulus'
import BookedTimesController from '../lib/role-call/BookedTimesController.js'
import RoleCallTimeline from '../lib/role-call/RoleCallTimeline.js'
import moment from 'moment'

export default class extends Controller {
  static values = { editable: Boolean }
  static targets = ['newBookedTime', 'updateBookedTime', 'expandCollapseIcon']
  static outlets = ['role-call-tool-palette']

  async connect() {
    const { groups, items } = await this.loadData()

    this.timeline = new RoleCallTimeline(
      this.element,
      this.editableValue,
      this.bookedTimesController(),
      groups,
      items
    )

    document.addEventListener('turbo:before-fetch-response', async (e) => {
      if (e.target.id === 'advanced-filter-group') {
        const { groups, items } = await this.loadData()
        this.timeline.updateAll(items, groups)
      }
    })
  }

  async collapseExpand() {
    var timeline = this.timeline
    var itemSet = timeline.timeline.itemSet

    if (this.expandCollapseIconTarget.textContent == 'collapse_all') {
      this.expandCollapseIconTarget.textContent = 'expand_all'
      timeline.groups.forEach(group => {
        if (group.showNested) {
          itemSet.toggleGroupShowNested(itemSet.groups[group.id])
        }
      })
    } else {
      timeline.groups.forEach(group => {
        this.expandCollapseIconTarget.textContent = 'collapse_all'
        if (!group.showNested) {
          itemSet.toggleGroupShowNested(itemSet.groups[group.id])
        }
      })
    }

    await this.timeline.updateCollapseSettingOnServer()
  }

  disconnect() {
    this.timeline?.disconnect?.()
  }

  bookedTimesController() {
    return this._bookedTimesController ??= new BookedTimesController()
  }

  visTimeline() { return this.timeline.timeline }

  /**
   * To avoid needing a full page refresh, this stimulus hook is used to pass data from the
   * rails controller and update the vis.js data live.
   * @param target
   */
  async newBookedTimeTargetConnected(target) {
    const data = JSON.parse(target.innerHTML)
    return this.timeline.addSavedBookedTime(data)
  }

  async updateBookedTimeTargetConnected(target) {
    const data = JSON.parse(target.innerHTML)
    return this.timeline.updateSavedBookedTime(data)
  }

  async loadData() {
    return this.bookedTimesController().index()
  }

  async timelineItemSelected(e) {
    const usingSplitTool = this.roleCallToolPaletteOutlet.splitToolActive()
    if (!e.detail || !usingSplitTool) return

    const bookedTimeId = e.detail.event.items[0]
    const event = e.detail.event.event

    const { x: itemX, width } = event.target.getBoundingClientRect()
    const clickX = event.center.x
    const relativeX = clickX - itemX
    const ratio = relativeX / width
    const response = await this.bookedTimesController().split(bookedTimeId, ratio)
    const result = await response.json()
    if (result.status === 'ok') {
      await this.timeline.updateSavedBookedTime(JSON.parse(result.booked_time))
      await this.timeline.addSavedBookedTime(JSON.parse(result.new_booked_time))
    } else {
      console.error(result.error)
    }
  }

  setZoom(event) {
    const currentWindow = this.visTimeline().getWindow()

    const zoom = event.target.value
    let newWindow
    switch(zoom) {
      case 'month':
        newWindow = {
          start: currentWindow.start,
          end: moment(currentWindow.start).add(1, 'month')
        }
        break
      case 'quarter':
        newWindow = {
          start: currentWindow.start,
          end: moment(currentWindow.start).add(2, 'weeks').add(3, 'months')
        }
        break
      case 'year':
        newWindow = {
          start: currentWindow.start,
          end: moment(currentWindow.start).add(2, 'weeks').add(1, 'year')
        }
        break
      default:
        newWindow = {}
    }

    this.visTimeline().setWindow(newWindow.start, newWindow.end, { animation: true })
  }

  resetWindow() {
    const currentWindow = this.visTimeline().getWindow()

    const newWindow = {
      start: moment().subtract(2, 'weeks'),
      end: moment().subtract(2, 'weeks').add(moment(currentWindow.end).subtract(moment(currentWindow.start)))
    }

    this.visTimeline().setWindow(newWindow.start, newWindow.end, { animation: true })
  }
}
