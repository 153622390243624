import { get } from '@rails/request.js'
import { Controller } from '@hotwired/stimulus'

export default class SelectFilterController extends Controller {
  static targets = [
    'hiddenField',
    'checkbox',
    'dropdown',
    'optionSearch',
    'menuItem',
    'dropdownTrigger',

    // Use with labelStyleValue = 'count'
    'dropdownTriggerLabel',

    // Use when labelStyleValue is not set
    'labelPlaceholder',
    'labelFirstSelection',
    'labelConjunction',
    'labelAdditionalSelection',
    'labelAdditionalSelectionCount'
  ]

  static values = {
    optionsUrl: String,
    triggerLabelPlural: String,
    triggerLabelSingular: String,
    labelStyle: String,
    collapseSelected: { type: Boolean, default: true }
  }

  static outlets = ['filter-group']

  connect() {
    this.updateTriggerLabel()
    if (this.hiddenFieldTarget.value.length > 0) {
      delete this.hiddenFieldTarget.dataset.disabled
      this.element.classList.remove('hidden')
      this.controlGroupChange()
    }
  }

  enable() {
    delete this.hiddenFieldTarget.dataset.disabled
    this.filterGroupOutlet.filterContainerTarget.insertBefore(this.element, this.filterGroupOutlet.secondaryAddFilterTarget)
    this.element.classList.remove('hidden')
    this.controlGroupChange()
    this.filterGroupOutlet.resetSavedFiltersLabelText()
    this.dropdownTarget.show()
  }

  disable() {
    this.hiddenFieldTarget.value = []
    this.setSelectedItemLabels([])
    this.updateTriggerLabel()
    this.checkboxTargets.forEach(checkbox => checkbox.checked = false)
    this.hiddenFieldTarget.dataset.disabled = true
    this.element.classList.add('hidden')
    this.controlGroupChange()
    this.filterGroupOutlet.resetSavedFiltersLabelText()
    this.submit()
    this.afterDropdownClose()
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  useNumberSelectedLabel() {
    return (this.hasTriggerLabelPluralValue && this.hasTriggerLabelSingularValue)
  }

  controlGroupChange() {
    const controlGroupChangeEvent = new CustomEvent('control-group-change')
    this.filterGroupOutletElement.dispatchEvent(controlGroupChangeEvent)
  }

  numberSelectedLabel(count) {
    if (count === 1) {
      return `1 ${this.triggerLabelSingularValue}`
    }
    return `${count} ${this.triggerLabelPluralValue}`
  }

  updateTriggerLabel() {
    if (this.labelStyleValue === 'count') {
      this.updateTriggerLabelCount()
    } else {
      this.updateTriggerLabelDescription()
    }
  }

  updateTriggerLabelCount() {
    const selectedItemLabels = this.element.dataset.selectedItemLabels ? JSON.parse(this.element.dataset.selectedItemLabels) : []
    const selectedItemsCount = selectedItemLabels.length

    this.dropdownTriggerLabelTarget.innerText = this.numberSelectedLabel(selectedItemsCount)
  }

  updateTriggerLabelDescription() {
    const selectedItemLabels = this.element.dataset.selectedItemLabels ? JSON.parse(this.element.dataset.selectedItemLabels) : []
    const selectedItemsCount = selectedItemLabels.length

    if (selectedItemsCount === 0) {
      this.labelPlaceholderTarget.classList.remove('hidden')
      this.labelFirstSelectionTarget.classList.add('hidden')
      this.labelConjunctionTarget.classList.add('hidden')
      this.labelAdditionalSelectionTarget.classList.add('hidden')
      return
    }

    if (selectedItemsCount > 0) {
      this.labelPlaceholderTarget.classList.add('hidden')
      this.labelFirstSelectionTarget.classList.remove('hidden')
      const labelText = this.collapseSelectedValue ? decodeURIComponent(selectedItemLabels[0]) : selectedItemLabels.map(decodeURIComponent).join(', ')
      this.labelFirstSelectionTarget.innerText = labelText
    }

    if (this.collapseSelectedValue) {
      if (selectedItemsCount > 1) {
        this.labelConjunctionTarget.classList.remove('hidden')
        this.labelAdditionalSelectionTarget.classList.remove('hidden')
  
        this.labelAdditionalSelectionCountTarget.innerText = selectedItemsCount - 1
      } else {
        this.labelConjunctionTarget.classList.add('hidden')
        this.labelAdditionalSelectionTarget.classList.add('hidden')
      }
    }
  }

  async hiddenFieldTargetConnected() {
    if (!this.hiddenFieldTarget.value) {
      this.hiddenFieldTarget.value = []
    }
    if (this.hasOptionSearchTarget) {
      await this.getOptions(this.optionSearchTarget.value)
    }
    const selectedItemsCount = (this.hiddenFieldTarget.value || []).filter(value => Boolean(value)).length
    if (selectedItemsCount > 0) {
      this.element.classList.remove('hidden')
    }
  }

  focusSearch() {
    if (this.hasOptionSearchTarget) {
      this.optionSearchTarget.focus()
    }
  }

  async afterDropdownClose() {
    if (this.hasOptionSearchTarget) {
      this.optionSearchTarget.value = ''
      await this.getOptions(this.optionSearchTarget.value)
    }
  }

  async onSearchInput(event) {
    await this.getOptions(event.target.value)
  }

  async getOptions(searchTerm) {
    await get(this.optionsUrlValue, {
      query: {
        search_term: searchTerm,
        selected_values: JSON.stringify(this.hiddenFieldTarget.value)
      },
      contentType: 'application/json',
      responseKind: 'turbo-stream',
    })
  }

  setSelectedItemLabels(newLabels) {
    this.element.dataset.selectedItemLabels = JSON.stringify(newLabels)
  }

  selectOption(event) {
    const selectedItem = event.detail.item.value
    const itemLabel = event.detail.item.getTextLabel()
    const checkbox = event.detail.item.querySelector('input[type="checkbox"')
    const selectedItemLabels = this.element.dataset.selectedItemLabels ? JSON.parse(this.element.dataset.selectedItemLabels) : []

    if (this.hiddenFieldTarget.value.includes(selectedItem)) {
      this.hiddenFieldTarget.value.splice(this.hiddenFieldTarget.value.findIndex(element => element === selectedItem), 1)
      this.setSelectedItemLabels(selectedItemLabels.filter(label => label !== itemLabel))
      checkbox.checked = false
    } else {
      this.hiddenFieldTarget.value.push(selectedItem)
      selectedItemLabels.push(itemLabel)
      this.setSelectedItemLabels(selectedItemLabels)
      checkbox.checked = true
    }
    this.updateTriggerLabel()
    this.filterGroupOutlet.resetSavedFiltersLabelText()
    this.submit()
  }

  checkboxTargetConnected(element) {
    const associatedMenuItem = element.parentElement.parentElement
    if (this.hiddenFieldTarget.value.includes(associatedMenuItem.value)) {
      element.checked = true
    }
  }

  submit() {
    const changeEvent = new CustomEvent('change')
    this.hiddenFieldTarget.dispatchEvent(changeEvent)
  }
}
