import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['defaultToolButton', 'splitToolButton']

  get activeClass() {
    return 'btn--active'
  }

  splitToolActive() {
    return this.splitToolButtonTarget.classList.contains(this.activeClass)
  }

  toggleTool(event) {
    const button = event.target.closest('.btn') ?? event.target
    if (button.classList.contains(this.activeClass)) return

    if (button === this.splitToolButtonTarget) {
      document.body.style.cursor = 'col-resize'
    } else {
      document.body.style.cursor = 'default'
    }
    this.defaultToolButtonTarget.classList.toggle(this.activeClass)
    this.splitToolButtonTarget.classList.toggle(this.activeClass)
  }
}
