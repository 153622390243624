import '@hotwired/turbo-rails'
import './controllers'

import './initializers/honeybadger'
import './initializers/turbo_confirm'
import './initializers/frame_missing_handler'

import * as ActiveStorage from "@rails/activestorage"

import './lib/shoelace'
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path'

setBasePath('/packs/shoelace')

ActiveStorage.start()

