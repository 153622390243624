import { Controller as StimulusController } from '@hotwired/stimulus'

/**
 * Stimulus controller to handle selection of an item in a dropdown (select),
 * where each item represents an individual page to which the app can be navigated/directed.
 */
export default class NavigableDropdownController extends StimulusController {
  static targets = ['dropdown']

  optionSelected(event) {
    Turbo.visit(this.url(event))
  }

  url(event) {
    if (event.target.dataset.mode === 'query') {
      return `${this.dropdownTarget.dataset.url}?${event.target.value}`
    }
    return this.urlWithSelectedId(event.target.value)
  }

  urlWithSelectedId(selectedId) {
    const deconstructedUrl = this.dropdownTarget.dataset.url.split('/')
    deconstructedUrl[deconstructedUrl.length - 1] = selectedId
    return deconstructedUrl.join('/')
  }
}
