import { Controller } from '@hotwired/stimulus'
import { animate, toggleVisible } from '../helpers/dom_helpers'

export default class BottomPanelController extends Controller {
  static targets = ['panel', 'backdrop']

  togglePanel() {
    if (this.panelTarget.classList.contains('visibility-hidden')) {
      toggleVisible(this.backdropTarget, true)
      toggleVisible(this.panelTarget, true)

      animate(this.panelTarget, 'slideInUp', 150)
      return
    }

    animate(this.panelTarget, 'slideOutDown')

    setTimeout(() => {
      toggleVisible(this.backdropTarget, false)
      toggleVisible(this.panelTarget, false)
    }, 150)
  }
}
