import { Controller } from '@hotwired/stimulus'

class DateSelectController extends Controller {
  static targets = [
    'startDateInput',
    'endDateInput',
    'allDayCheckbox',
    'timeFields',
    'endTimeInput',
    'syncFifteenFiveCheckbox'
  ]

  connect() {
    super.connect()
    this._refreshCheckboxStatus()
    this.timeFieldsTarget.classList.toggle('hidden', !this.endTimeInputTarget.value)
    this.allDayCheckboxTarget.checked = !this.endTimeInputTarget.value
  }

  startDateSelected(event) {
    if (this.endDateInputTarget.value > event.target.value) {
      this._refreshCheckboxStatus()
      this._updateSyncFifteenFiveCheckbox()
      return
    }

    this.endDateInputTarget.value = event.target.value
    this.endDateInputTarget.min = event.target.value
    this._callOnChange(this.endDateInputTarget)
  }

  startTimeSelected(event) {
    if (this.endTimeInputTarget.value > event.target.value) return

    this.endTimeInputTarget.value = event.target.value
    this.endTimeInputTarget.min = event.target.value
    this._callOnChange(this.endTimeInputTarget)
  }

  endDateSelected() {
    this._refreshCheckboxStatus()
    this._updateSyncFifteenFiveCheckbox();
  }

  toggleTimeFields(event) {
    this.timeFieldsTarget.classList.toggle('hidden', event.target.checked)
  }

  _refreshCheckboxStatus() {
    const startDate = this.startDateInputTarget.value
    const endDate = this.endDateInputTarget.value
    const datesAreOnDifferentDays = Boolean(startDate) && Boolean(endDate) && startDate !== endDate

    this._updateAllDayCheckbox(datesAreOnDifferentDays);
  }

  _updateAllDayCheckbox(datesAreOnDifferentDays) {
    if (datesAreOnDifferentDays) {
      this.allDayCheckboxTarget.disabled = true;
      this.allDayCheckboxTarget.checked = true;
      this._callOnChange(this.allDayCheckboxTarget);
    } else {
      this.allDayCheckboxTarget.disabled = false;
    }
  }

  _updateSyncFifteenFiveCheckbox() {
    if (this._containsMonThroughFri(this.startDateInputTarget.value, this.endDateInputTarget.value)) {
      this.syncFifteenFiveCheckboxTarget.disabled = false;
      this.syncFifteenFiveCheckboxTarget.checked = true;
      this._callOnChange(this.syncFifteenFiveCheckboxTarget);
    } else {
      this.syncFifteenFiveCheckboxTarget.disabled = true;
      this.syncFifteenFiveCheckboxTarget.checked = false;
    }
  }

  _callOnChange(element) {
    element.dispatchEvent(new Event('change'))
  }

  _containsMonThroughFri(startDateStr, endDateStr) {
    const startDateWithoutTimeZone = new Date(startDateStr)
    const endDateWithoutTimeZone = new Date(endDateStr)

    const userTimezoneOffset = startDateWithoutTimeZone.getTimezoneOffset() * 60000
    const correctedStartDate = new Date(startDateWithoutTimeZone.getTime() + userTimezoneOffset)
    const correctedEndDate = new Date(endDateWithoutTimeZone.getTime() + userTimezoneOffset)

    const totalTimeDifference = correctedEndDate.getTime() - correctedStartDate.getTime()
    const totalDays = Math.round(totalTimeDifference / (1000 * 3600 * 24))

    const dateRange = Array.from({ length: totalDays + 1 }, (_, index) => {
        const date = new Date(correctedStartDate)
        date.setDate(correctedStartDate.getDate() + index)
        return date
    })

    const dateDayNumbers = dateRange.map(date => date.getDay())
    const firstMondayIndex = dateDayNumbers.findIndex(day => day === 1)
    if (firstMondayIndex === -1) return false

    return dateDayNumbers.slice(firstMondayIndex + 1).some(day => day === 5)
  }
}

export default DateSelectController
