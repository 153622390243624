import PropTypes from 'prop-types'
import React from 'react'
import { VictoryBar, VictoryChart, VictoryAxis, VictoryStack, VictoryLine, VictoryVoronoiContainer } from 'victory'

export default class CadenceChart extends React.Component {
  static propTypes = {
    data: PropTypes.object
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { billable, nonbillable, mark, tickLabels } = this.props.data

    const primaryColor = 'var(--color-chart)'

    return (
      <VictoryChart
        height={180}
        domain={{ x: [1, 15], y: [0, 12] }}
        domainPadding={{ x: [15, 15] }}
        padding={{ left: 0, right: 0, top: 0, bottom: 50 }}
        containerComponent={
          <VictoryVoronoiContainer
            labels={({ datum }) => `${datum.y} hours ${datum.childName}`}
            voronoiDimension='x'
            voronoiBlacklist={['markLine']}
          />
        }
      >
        {/* Time Data */}
        <VictoryStack>
          <VictoryBar
            name='billable'
            style={{
              data: { fill: primaryColor }
            }}
            barWidth={20}
            data={billable}
          />
          <VictoryBar
            name='nonbillable'
            style={{
              data: { fill: primaryColor }
            }}
            barWidth={20}
            data={nonbillable}
          />
        </VictoryStack>

        {/* 8 Hour Mark */}
        <VictoryLine
        name='markLine'
          style={{
            data: { stroke: primaryColor, strokeWidth: 1 }
          }}
          data={[
            { x: 0, y: mark },
            { x: 16, y: mark },
          ]}
        />

        {/* X Axis */}
        <VictoryAxis
          tickFormat={(t) => tickLabels[t-1]}
          tickCount={15}
          style={{
            axis: { strokeWidth: 1, stroke: primaryColor },
            tickLabels: { fontSize: 10, stroke: primaryColor }
          }}
        />

        {/* Y Axis */}
        <VictoryAxis
          dependentAxis
          tickFormat={() => ''}
          style={{
            axis: { stroke: primaryColor }
          }}
        />
      </VictoryChart>
    )
  }
}
