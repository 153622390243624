import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  async connect() {
    this.addManifestLink()
    this.addAssetLinks()

    if (navigator.serviceWorker) {
      if (navigator.serviceWorker.controller) {
        // If the service worker is already running, skip to state change
        this.stateChange()
      } else {
        // Register the service worker, and wait for it to become active
        navigator.serviceWorker
          .register('/service-worker.js', { scope: '/' })
          .then(function (reg) {
            console.log('[Companion]', 'Service worker registered!')
            console.log(reg)
          })
        navigator.serviceWorker.addEventListener(
          'controllerchange',
          this.controllerChange.bind(this)
        )
      }
    }
  }

  controllerChange(event) {
    navigator.serviceWorker.controller.addEventListener(
      'statechange',
      this.stateChange.bind(this)
    )
  }

  stateChange() {
    // perform any visual manipulations here
  }

  addManifestLink() {
    const link = document.createElement('link')
    link.rel = 'manifest'
    const params = this._urlParams({ foreground: this._foreground(), background: this._background() })
    link.href = `/manifest.json?${params}`
    document.head.append(link)
  }

  addAssetLinks() {
    this._addAppleIcons()
    this._addStandardIcons()
    this._addSplashscreenLinks()
  }

  _addStandardIcons() {
    const icon_sizes = [192, 96, 32, 16]

    icon_sizes.forEach(size => this._addIconLink(size, 'icon'))
  }

  _addAppleIcons() {
    const apple_sizes = [1024, 512, 256, 120]

    apple_sizes.forEach(size => this._addIconLink(size, 'apple-touch-icon'))
  }

  _addIconLink(size, rel) {
    const params = this._urlParams({ foreground: this._foreground(), background: this._background(), size })
    const link = document.createElement('link')
    link.rel = rel
    link.href = `/icon?${params}`
    link.sizes = `${size}x${size}`
    link.type = 'image/png'
    document.head.append(link)
  }

  _addSplashscreenLinks() {
    const deviceWidth = window.screen.width * window.devicePixelRatio
    const deviceHeight = window.screen.height * window.devicePixelRatio

    this._addSplashscreenLink(deviceWidth, deviceHeight, 'portrait')
    this._addSplashscreenLink(deviceHeight, deviceWidth, 'landscape')
  }

  _addSplashscreenLink(width, height, orientation) {
    const params = this._urlParams({
      background: this._background(),
      foreground: this._foreground(),
      top_color: this._topColor(),
      width,
      height,
    })

    const link = document.createElement('link')
    link.rel = 'apple-touch-startup-image'
    link.href = `/splashscreen?${params}`
    link.type = 'image/png'
    link.media = `screen and (orientation: ${orientation})`
    document.head.append(link)
  }

  _foreground() {
    return getComputedStyle(document.documentElement).getPropertyValue('--color-primary-button') ||
        getComputedStyle(document.documentElement).getPropertyValue('--op-color-primary-base')
  }

  _background() {
    return getComputedStyle(document.documentElement).getPropertyValue('--color-primary-button-text')
  }

  _topColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--color-background-top')
  }

  _urlParams(params) {
    return new URLSearchParams(Object.entries(params))
  }
}
