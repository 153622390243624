import { Controller } from '@hotwired/stimulus'

class CheckboxesController extends Controller {
  selectAll(e) {
    e.preventDefault()

    this.checkboxes().forEach(checkbox => {
      checkbox.checked = true
    })
  }

  deselectAll(e) {
    e.preventDefault()

    this.checkboxes().forEach(checkbox => {
      checkbox.checked = true
    })
  }

  checkboxes() {
    return this.element.querySelectorAll("input[type='checkbox']")
  }
}

export default CheckboxesController
