import { Controller } from '@hotwired/stimulus'
import tippy from 'tippy.js';

// For instruction on how to use this tooltip, see https://atomiks.github.io/tippyjs/v6/constructor/

// Note: This is instead of Optics tooltips since they don't support edge detection or clipping.

export default class extends Controller {
  connect() {
    if (this.shouldHideTooltip()) { return }

    tippy(this.element, { allowHTML: true })
  }

  shouldHideTooltip() {
    // Note: This will not update on resize
    const style = getComputedStyle(document.body)
    const breakpoint = style.getPropertyValue('--op-breakpoint-medium')
    const onLargeScreen =  window.innerWidth > Number(breakpoint.replace('px', ''))

    return onLargeScreen && this.isSidebarItem() && !this.sidebarStyleRequiresTooltip()
  }

  sidebarStyleRequiresTooltip() {
    return this.element.closest('.sidebar').classList.contains('sidebar--rail')
  }

  isSidebarItem() {
    return this.element.classList.contains('sidebar-nav__item')
  }
}
