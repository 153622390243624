import { Controller } from '@hotwired/stimulus'

export default class AutoSaveFormController extends Controller {
  static values = {
    // Configuration: whether to replace the full form with the server response
    // when saving, or limit to user-modified fields. Defaults to replacing only
    // modified fields if the form is inside a turbo-frame.
    fullReplace: { type: Boolean, default: false },
  }

  static targets = ['form']

  onlyUpdateChangedFields() {
    return !this.fullReplaceValue
  }

  get form() {
    if (this.hasFormTarget) return this.formTarget

    return this.element
  }

  connect() {
    if (this.onlyUpdateChangedFields()) {
      this.modifiedFieldIds = new Set()
      this.turboFrame = this.form.closest('turbo-frame')

      if (!this.turboFrame) {
        // No turbo-frame means no Turbo events to customize replacing elements.
        // Fall back to replacing the full form/page.
        this.fullReplaceValue = true
      } else {
        this.turboFrame.addEventListener('turbo:before-frame-render', this.updateModifiedFields)
      }
    }
  }

  disconnect() {
    if (this.onlyUpdateChangedFields()) {
      this.turboFrame.removeEventListener('turbo:before-frame-render', this.updateModifiedFields)
    }
  }

  updateModifiedFields = event => {
    event.detail.render = (currentElement, newElement) => {
      this.modifiedFieldIds.forEach(modifiedFieldId => {
        const selector = `#${modifiedFieldId}`
        const currentField = currentElement.querySelector(selector)
        const newField = newElement.querySelector(selector)

        // The user may be typing in this element, so don't replace it. The next
        // auto-save should update this field from the server.
        if (document.activeElement === currentField) return

        // simple_form creates a div.form-group around each input, which is what
        // contains any error messages, or gets an error class.
        const currentGroup = currentField.closest('.form-group') || currentField.closest('.form__group')
        const newGroup = newField.closest('.form-group') || newField.closest('.form__group')

        currentGroup.replaceWith(newGroup)
      })

      const streamElements = newElement.querySelectorAll('turbo-stream')
      currentElement.append(...streamElements)

      this.modifiedFieldIds.clear()
      // This is only needed when in "only modified" mode. "Full replace" mode
      // will swap out the form element, effectively clearing this data
      // attribute in the process.
      delete this.form.dataset.autosaveInProgress
    }
  }

  submit(event) {
    this.form.dataset.autosaveInProgress = true
    this._removeDataAttributeAfterSave()

    if (this.onlyUpdateChangedFields()) {
      // Track which fields have been modified since the last save. Note that
      // calling requestSubmit will cancel any in-flight save requests.
      this.modifiedFieldIds.add(event.target.id)
    }

    this.form.requestSubmit()

  }

  _removeDataAttributeAfterSave() {
    // Most of the time, nothing special needs to happen. The form will be
    // replaced with a version from the server without the data attribute.
    if (!this.form.dataset.turboFrame) return
    if (this.form.dataset.turboFrame === '_top') return

    const turboTarget = document.getElementById(this.form.dataset.turboFrame)
    if (!turboTarget) return

    // When the form targets a specific turbo frame, we can't rely on the form
    // being replaced from the server. Wait for the turbo frame to rerender,
    // then remove the data attribute.
    const removeAutosaveDataAttribute = () => {
      delete this.form.dataset.autosaveInProgress
      turboTarget.removeEventListener('turbo:frame-render', removeAutosaveDataAttribute)
    }

    turboTarget.addEventListener('turbo:frame-render', removeAutosaveDataAttribute)
  }
}
