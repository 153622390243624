class ApiClient {
  async fetch(url, method = 'GET', body) {
    const fullUrl = `${this.baseUrl}${url}`
    const response = await fetch(fullUrl, {
      method: method,
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': this._csrfToken()
      },
      credentials: 'same-origin',
    })

    if (!response.ok) {
      const { errors } = await response.json()
      return alert(`Internal error: ${errors[0]}`)
    }
    return response
  }

  get baseUrl() {
    return 'api/'
  }

  _csrfToken() {
    return this.__csrfToken ??= document.querySelector(`meta[name='csrf-token']`).content
  }
}

export default ApiClient
