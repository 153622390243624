import DateChartController from './date_chart_controller.js'

class PulseChartController extends DateChartController {
  static values = {
    ...super.values,
    submissions: Array
  }

  _showLegend() { return false }

  _tooltipAfterBody(tooltipItem) {
    const submissionCount = this.submissionsValue[tooltipItem[0].dataIndex]
    if (!submissionCount) return

    return `Submissions: ${submissionCount}`
  }
}

export default PulseChartController
