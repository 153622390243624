import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'
import moment from 'moment'

export default class ReportTimeRangeSelection extends React.PureComponent {
  static defaultProps = {
    options: ['Week', 'Month', 'Quarter'],
    defaultOption: 'Quarter',
  }

  static propTypes = {
    report: PropTypes.object,
    options: PropTypes.array,
    defaultOption: PropTypes.string,
  }

  constructor(props) {
    const { start_date, end_date, range_string } = props.report

    super(props)

    const isCustomReport = range_string === 'Custom'
    this.state = {
      open: false,
      customFormVisible: false,
      startDate: isCustomReport ? moment(start_date) : null,
      endDate: isCustomReport ? moment(end_date) : null
    }
  }

  _toggleDropdown(event) {
    event.preventDefault()
    this.setState({ open: !this.state.open })
  }

  _toggleCustomForm(event) {
    event.preventDefault()
    this.setState({ open: false, customFormVisible: true })
  }

  _rangeLinkQuery(option) {
    if (option === 'All') return '?'

    if (option === 'Previous Week') {
      const start_date = moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
      const end_date = moment().subtract(1, 'week').endOf('week').format('YYYY-MM-DD')
      return `?end_date=${end_date}&range_string=${option}&start_date=${start_date}`
    }

    if (option.includes('Last')) {
      const time_values = option.substring(5).split(' ') // e.g ['3', 'Months']
      let time_number
      let time_set
      if (time_values.length > 1) {
        time_number = time_values[0]
        time_set = time_values[1]
      } else {
        time_number = 1
        time_set = time_values[0]
      }

      const start_date = moment().subtract(time_number, time_set?.toLowerCase()).format('YYYY-MM-DD')
      const end_date = moment().format('YYYY-MM-DD')
      return `?end_date=${end_date}&range_string=${option}&start_date=${start_date}`
    }

    if (option === 'YTD') option = 'Year'

    const start_date = moment().startOf(option).format('YYYY-MM-DD')
    const end_date = moment().endOf(option).format('YYYY-MM-DD')
    return `?end_date=${end_date}&range_string=${option}&start_date=${start_date}`
  }

  _rangeLinkClasses(option) {
    let classes = 'chip'
    const params = window.location.href.split('?')[1]
    if (params) {
      const paramsString = '?' + params
      const optionParams = this._rangeLinkQuery(option).replaceAll(' ', '%20')
      if (paramsString === optionParams) classes += ' active'
    } else {
      if (option === this.props.defaultOption) classes += ' active'
    }
    return classes
  }

  renderRangeOptions() {
    return this.props.options.map(option => {
      return (
        <li key={option}>
          <a className={this._rangeLinkClasses(option)} href={this._rangeLinkQuery(option)}>{option}</a>
        </li>
      )
    })
  }

  render() {
    const { customFormVisible, focusedInput, startDate, endDate } = this.state

    const isCustomReport = this.props.report.range_string === 'Custom'
    const customChipClasses = isCustomReport ? ' active' : ''

    return (
      <React.Fragment>
        <ul className="chips custom-range__chips">
          {this.renderRangeOptions()}
          <li key="Custom">
            <a className={"chip" + customChipClasses} href="#" onClick={this._toggleCustomForm.bind(this)}>Custom</a>
          </li>
        </ul>
        <div className={classNames('custom-range', { 'custom-range--visible' : customFormVisible })}>
          <form action="" method="get" id="custom-range-form">
            <input type="hidden" name="range_string" value="Custom" />
            <DateRangePicker
              endDate={endDate}
              endDateId="end_date"
              startDate={startDate}
              startDateId="start_date"
              anchorDirection="right"
              displayFormat="YYYY-MM-DD"
              focusedInput={focusedInput}
              initialVisibleMonth={() => moment().subtract(1, 'month')}
              isOutsideRange={() => false}
              onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
              onFocusChange={focusedInput => this.setState({ focusedInput })}
              required={true}
              hideKeyboardShortcutsPanel
              noBorder
              small
            />
            <button className='btn btn--primary btn--small margin-left-sm' type="submit">Update Report</button>
          </form>
        </div>
      </React.Fragment>
    )
  }
}
